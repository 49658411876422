import React from "react";
import "../bulma.scss";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeroSection from "../components/HeroSection";
import CTABanner from "../components/CtaBanner";
import { useStaticQuery, graphql } from "gatsby";
import ItemColumns from "../components/ItemColumns";
import ContentItemButtons from "../components/ContentItemButtons";
import BorderedTitle from "../components/BorderedTitle";

import EssentialDevops from "../images/svgs/essential-devops.svg";
import DevopsConsulting from "../images/svgs/devops-consulting.svg";
import CustodiaFontes from "../images/svgs/custodia-fontes.svg";
import AutomacaoEntregas from "../images/svgs/automacao-entregas.svg";
import ControleTempoReal from "../images/svgs/controle-tempo-real.svg";
import ProtejaNegocio from "../images/svgs/proteja-seu-negocio.svg";
import ElimTarefas from "../images/svgs/eliminacao-tarefas.svg";
import ExpFalaMais from "../images/svgs/exp-fala-alto.svg";

import MonitoriamentoApps from "../images/svgs/monitoriamento-apps.svg";
import AbordagemConsult from "../images/svgs/abordagem-consult.svg";
import SintoniaTimes from "../images/svgs/sintonia-times.svg";
import TimeToMarket from "../images/svgs/menir-time-market.svg";
import Seguranca from "../images/svgs/aumento-seguranca.svg";

import VisibRoadmap from "../images/svgs/visib-roadmap.svg";
import DesignThink from "../images/svgs/design-thinking.svg";
import FormacaoTimes from "../images/svgs/formacao-times.svg";
import EliminacaoJanelas from "../images/svgs/eliminacao-jan.svg";
import ContinuoAprim from "../images/svgs/continuo-apr.svg";

const EasyDevopsPage = () => {
  const { devopsBanner, devopsBannerLast, seoImg } = useStaticQuery(
    graphql`
      query {
        devopsBanner: file(
          relativePath: { eq: "devops-1-right_960x720px.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 990, maxHeight: 720, quality: 100, fit: FILL) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        devopsBannerLast: file(relativePath: { eq: "devops-3_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 600, fit: FILL) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        seoImg: file(
          relativePath: { eq: "thumbnail_devops-consulting_500x300px.png" }
        ) {
          childImageSharp {
            fixed(width: 500, height: 300) {
              src
            }
          }
        }
      }
    `
  );
  const easyDevopsItemsTheme = {
    main: "var(--esx-devops)",
    color: "var(--esx-devops)",
    gradient: "#fafafa",
    textColor: "var(--esx-dark)",
  };
  const easyDevopsItemsThemeInvert = {
    main: "var(--esx-devops)",
    color: "var(--esx-devops)",
    gradient: "var(--dark-gradient)",
    textColor: "#fafafa",
    boldTextColor: "var(--esx-devops)",
  };
  const easyDevopsSectionsTheme = {
    main: "var(--esx-devops)",
    color: "var(--esx-devops)",
    gradient: "var(--dark-gradient)",
    textColor: "#fafafa",
    boldTextColor: "white",
    titleColor: "var(--esx-devops)",
  };

  const items = [
    {
      icon: <EssentialDevops width="60" height="60" />,
      title: "Essential DevOps",
      description:
        "<strong>Um pequeno passo para você, um grande salto para sua organização.</strong><br/><br/> O Essential DevOps é um conjunto de serviços com uma visão e abordagem mais prática dos conceitos, processos e ferramentas de DevOps. Acreditamos que é possível obter resultados significativos, rápidos e sem grandes investimentos. Inicie agora a jornada pelo que é mais sensível e importante para sua organização.",
      anchorLink: "/devops-consulting#essential-devops",
    },
    {
      icon: <DevopsConsulting width="60" height="60" />,
      title: "Master DevOps",
      description:
        "<strong>Eleve ao máximo a maturidade dos processos e boas práticas de DevOps na sua organização.</strong><br/><br/>Dê o próximo passo e implemente um roadmap que busque grandes alvos e evolua em sintonia com a organização e seus objetivos de negócio, promovendo uma real evolução dos times de tecnologia e gerando resultados consistentes e duradouros.",
      anchorLink: "/devops-consulting#master-devops",
    },
  ];
  const contentItems = [
    {
      sectionTitle: "Essential DevOps",
      id: "essential-devops",
      itemButtons: [
        {
          icon: <CustodiaFontes width="50" height="50" />,
          title: "Recuperação da custódia de fontes",
          text:
            "Obtenha controle sobre os códigos-fonte em posse de provedores terceiros, ao restringir paulatinamente a entrada de versões em produção que não estejam no repositório de fontes.",
        },
        {
          icon: <AutomacaoEntregas width="50" height="50" />,
          title: "Automação de entregas",
          text:
            "Automatize as atividades que compõem a fase de entrega das soluções, desde a montagem de pacotes do software, até sua instalação nos ambientes de teste, homologação e produção, paralelamente à execução dos testes requeridos em cada etapa.",
        },
        {
          icon: <MonitoriamentoApps width="50" height="50" />,
          title: "Monitoramento de aplicações",
          text:
            "Escolha os indicadores pertinentes ao seu negócio e seus desdobramentos nas soluções de tecnologia e capture tanto eventos e métricas técnicas, como disponibilidade de componentes vitais para as operações.",
        },
      ],
      motives: {
        title: "Porque escolher a ESX",
        motives: [
          {
            icon: <ExpFalaMais width="60" height="60" />,
            title: "Experiência fala mais alto",
            description:
              "Temos como legado o reconhecimento de melhor empresa parceira ALM do mundo pela Microsoft, com vivência e experiência em vários cenários complexos e organizações de setores diferentes.",
          },
          {
            icon: <AbordagemConsult width="60" height="60" />,
            title: "Abordagem consultiva",
            description:
              "Nossos consultores avaliam os aspectos de negócio para compreender objetivos, prioridades e riscos, o que lhes permite direcionar soluções e abordagens com mais assertividade e alinhadas com a alta gestão.",
          },
          {
            icon: <VisibRoadmap width="60" height="60" />,
            title: "Roadmap futuro",
            description:
              "Ao final  do trabalho, caso vocês queiram trilhar outro nível de maturidade dos processos e práticas de DevOps, geramos o roadmap do programa Master DevOps, com suas prioridades, objetivos, estimativas de investimento, benefícios e retorno.",
          },
        ],
      },
      gains: [
        {
          icon: <ControleTempoReal width="60" height="60" />,
          title: "Controle em tempo real",
          description:
            "Melhor visibilidade sobre as demandas de clientes e usuários, organizando o trabalho da equipe e aumentando a eficácia dos resultados.",
        },
        {
          icon: <ElimTarefas width="60" height="60" />,
          title: "Elimine tarefas repetidas",
          description:
            "Recursos como pipelines de build e release, eliminam tarefas manuais e repetitivas que consomem o tempo do seu time e ainda geram erros frequentes em entregas. ",
        },
        {
          icon: <ProtejaNegocio width="60" height="60" />,
          title: "Proteja seu negócio",
          description:
            "Implante técnicas e processos de qualidade nas automações de testes e evite impactos prejudiciais que podem ocasionar riscos ao negócio.",
        },
      ],
    },
    {
      sectionTitle: "Master DevOps",
      id: "master-devops",
      itemButtons: [
        {
          icon: <DesignThink width="50" height="50" />,
          title: "Integração entre Design Thinking, Agilidade e DevOps",
          text:
            "Utilizamos a metodologia de Design Thinking para abordar pontos críticos em todo o ciclo de desenvolvimento e promover inovações, suportando o gerenciamento de backlogs com métodos ágeis de testes, validações de hipóteses e implantações.",
        },
        {
          icon: <FormacaoTimes width="50" height="50" />,
          title: "Formação de times multidisciplinares",
          text:
            "Reunimos todas as competências necessárias para construir e evoluir produtos e serviços com agilidade e transparência, rompendo silos e facilitando o compartilhamento de aprendizados com histórico de entregas, rastreabilidade e métricas de desempenho.",
        },
        {
          icon: <EliminacaoJanelas width="50" height="50" />,
          title: "Eliminação de janelas de implantação",
          text:
            "Remova por completo as janelas de atualização das aplicações e eleve a maturidade dos processos de homologação aplicando testes com usuários (UAT), passando pela automação completa de pipelines, uso de feature flags e estratégias sofisticadas de deploy e release.",
        },
      ],
      gains: [
        {
          icon: <SintoniaTimes width="60" height="60" />,
          title: "Mais sintonia dos entre os times de tecnologia e de negócio",
          description:
            "Ao promover interações transparentes entre os times as entregas se tornam mais eficazes e alinhadas aos objetivos finais.",
        },
        {
          icon: <TimeToMarket width="60" height="60" />,
          title: "Menor time to market",
          description:
            "A eficiência obtida com a otimização de processos e automação de atividades permite diminuir sensivelmente o tempo de lançamento e aprimoramento de serviços e produtos.",
        },
        {
          icon: <Seguranca width="60" height="60" />,
          title: "Aumento do nível de segurança",
          description:
            "Sem perder agilidade, garanta a implantação intrínseca de processos e automações de segurança da aplicação e sua infraestrutura, mesmo que ocorram mudanças nas tecnologias e nos times.",
        },
        {
          icon: <ContinuoAprim width="60" height="60" />,
          title: "Melhor suporte à Governança",
          description:
            "A automação de processos suporta frameworks de compliance e governança, promovendo transparência nas áreas de negócio.",
        },
      ],
    },
  ];

  return (
    <Layout>
      <SEO
        title="Devops Consulting"
        image={seoImg}
        description="O serviço de DevOps Consulting tem como propósito melhorar os resultados dos times que produzem e operam aplicações, através da implantação efetiva de processos, práticas e da utilização de ferramentas que organizam e automatizam todo o fluxo de desenvolvimento, entregas e administração de soluções tecnológicas."
      />
      <HeroSection
        theme={easyDevopsSectionsTheme}
        title="DevOps Consulting"
        text="O serviço de <strong>DevOps Consulting</strong> tem como propósito melhorar os resultados dos times que produzem e operam aplicações, através da implantação efetiva de processos, práticas e da utilização de ferramentas que organizam e automatizam todo o fluxo de desenvolvimento, entregas e administração de soluções tecnológicas."
        fixedImage={devopsBanner.childImageSharp.fluid}
        buttonText="Fale agora com a gente"
        buttonLink="/contato-devops-consulting"
        openAsExternalLink
      />
      <ItemColumns
        items={items}
        theme={easyDevopsItemsTheme}
        disableAnimation
      />
      <CTABanner
        title="Conheça o DevOps Consulting"
        subtitle=" "
        buttonText="Fale agora com a gente"
        theme={{ main: easyDevopsItemsTheme.color }}
        buttonLink="/contato-devops-consulting"
        openAsExternalLink
      />

      {contentItems.map((ci, key) => (
        <>
          <hr id={ci.id} style={{ margin: 0 }}></hr>
          <ContentItemButtons
            sectionTitle={ci.sectionTitle}
            key={key}
            items={ci.itemButtons}
            externalKey={key}
            theme={easyDevopsSectionsTheme}
          />
          <BorderedTitle
            title="Ganhos"
            borderColor={easyDevopsItemsTheme.color}
            align="center"
            dense
          />
          <ItemColumns items={ci.gains} theme={easyDevopsItemsTheme} />
          {ci.motives ? (
            <ItemColumns
              items={ci.motives.motives}
              mainTitle={ci.motives.title}
              theme={easyDevopsItemsThemeInvert}
              disableAnimation
            />
          ) : null}
          {key === 0 ? (
            <CTABanner
              title="Conheça o DevOps Consulting"
              subtitle=" "
              buttonText="Fale agora com a gente"
              theme={{ main: easyDevopsItemsTheme.color }}
              buttonLink="/contato-devops-consulting"
              openAsExternalLink
            />
          ) : null}
        </>
      ))}

      <HeroSection
        changeHeight="95vh"
        buttonText="Fale agora com a gente"
        buttonLink="/contato-devops-consulting"
        openAsExternalLink
        forceTextAlignLeft
        theme={easyDevopsSectionsTheme}
        title="Nosso programa DevOps é diferente"
        text="<ul>
          <li><strong>Adoção de Cloud: </strong>Incorporamos ao seu roadmap estratégias sustentáveis de adoção e utilização de serviços Cloud, respeitando o seu cenário atual e acompanhando a evolução das suas aplicações.</li>
          <li><strong>DataOps: </strong>Construímos sua base de inteligência operacional conectando dados essenciais do projeto com o poder analítico da IA, para fornecer aos times insights com escala e agilidade, suportar práticas de automatização e
          promover a eficiência contínua dos negócios.</li>
          <li><strong>Especialistas: </strong>São mais de 7 anos de história, 300 projetos realizados e 100 clientes atendidos no Brasil e no exterior.
          Nossos consultores estão em constante formação e são líderes ativos da comunidade técnica</li>
        </ul>"
        fixedImage={devopsBannerLast.childImageSharp.fluid}
      />
    </Layout>
  );
};

export default EasyDevopsPage;
